import axios from "axios";

export default axios.create({
    //baseURL: "http://localhost:8082",
    baseURL: "https://latvianwood.tikla-eksperts.lv",
    //baseURL: "https://t9n4ff5cr9.execute-api.eu-north-1.amazonaws.com/prod",
    responseType: 'json',
    timeout: 10000,
    //withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    }

});
