import React from 'react';
import {Dati} from "../App";
import Highcharts, {GanttPointOptionsObject, SeriesOptionsType} from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


//var menesis = ["Jan", "Feb", "Mar", "Apr", "Mai", "Jūn", "Jūl", "Aug", "Sep", "Okt", "Nov", "Dec"]
export const GrafiksProcentiViews = (props:{dati:Dati[]}) => {

    const params = new URLSearchParams(window.location.search);
    const lang = params.get('lang') || 'lv';
    var menesis: string[];
    let lapatext:{videja_cena_din:string, nav_datu:string}
    switch (lang){
        case 'lv':
            menesis = require('./Menesi_short-lv.json')
            lapatext = require('../lapas_dati-lv.json')
            break;
        case 'en':
            menesis = require('./Menesi_short-en.json')
            lapatext = require('../lapas_dati-en.json')
            break;
        default:
            menesis = require('./Menesi_short-lv.json')
            lapatext = require('../lapas_dati-lv.json')

    }



    if (props.dati && props.dati.length >= 1) {
        const changeDate = (date: string | undefined) => {
            if (date) {
                let datums = date.split("-")
                return `${menesis[+datums[1] - 1]}(${datums[0]})`
            }
        }

        const calculateProcents = (dati: { name?: string; value?: number }[] | undefined, valueCalc: number | undefined, indeks: number) => {
            var base
            if (dati) {
                base = dati[indeks - indeks];
            }
            if (base?.value && valueCalc && dati) {
                if (dati[indeks - indeks].value !== undefined) {

                    var calc = ((valueCalc * 100) / +base.value)
                    var calc1 = calc - 100;
                    return +calc1.toFixed(0)
                }
            }
        }

        let assortments: string[] = [];
        var ind = 0;
        var sk = 0;
        for (let i = 0; i < props.dati.length; i++) {
            // @ts-ignore
            if (sk < props.dati[i].vertibas?.length) {
                if (props.dati[i].vertibas) {
                    // @ts-ignore
                    sk = props.dati[i].vertibas.length;
                }
                ind = i;
            }
        }


        props.dati[ind].vertibas?.map(dati => {
                // @ts-ignore
                return assortments.push(changeDate(dati.name));
            }
        )

        let dd: SeriesOptionsType[] = [];
        props.dati.map(dati => {
            let vertib: GanttPointOptionsObject[] = [];
            for (let i = 0; i < assortments.length; i++) {
                // @ts-ignore
                vertib.push(0)
            }


            dati.vertibas?.map((vertibas, index) => {
                var men = changeDate(vertibas.name);
                // @ts-ignore
                var ind = assortments.indexOf(men);
                if (vertibas.value != null) {

                    var ver = calculateProcents(dati.vertibas, vertibas.value, index)

                    // @ts-ignore
                    if (ver < 0){
                        // @ts-ignore
                        vertib[ind] = {y: ver, color:'#ff0000' }
                    } else {
                        // @ts-ignore
                        vertib[ind] = ver
                    }

                }
            })

            // @ts-ignore
            let strin = dati.grupa.split('|').map(segment => {
                let trimmedSegment = segment.trim();
                // @ts-ignore
                return lapatext[trimmedSegment] || trimmedSegment;
            }).join(' | ');

            var va: SeriesOptionsType = {
                type: 'line',
                name: strin,
                data: vertib
            }
            dd.push(va)


        })


        const options: Highcharts.Options = {
            title: {
                text: ''
            },
            chart: {
                type: 'Highcharts',
                style: {
                    fontFamily: "Roboto Condensed, sans-serif",
                    fontWeight: "normal"
                },
                borderColor: '#ff6a00',
                borderRadius: 20,
                borderWidth: 2,
                backgroundColor: '#fcf2e0'
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: true
                },
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{y} %'
                    }
                }
            },
            tooltip: {valueSuffix: " %"},
            legend: {
                borderRadius: 0,
                borderWidth: 0,
                align: "center",
                x: 15
            },
            xAxis: {
                categories: assortments,
                gridLineWidth: 1,
                lineColor: "#ffc861"
            },
            yAxis: {
                title: {
                    text: lapatext.videja_cena_din,
                },

                plotLines: [{
                    value: 0,
                    width: 1,
                }],
                labels: {
                    formatter: function () {
                        return this.value + ' %';
                    }
                }
            },
            series: dd
        }


        return (
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        );
    } else {
        return (
            <div className="alert alert-danger alert-dismissible fade show">
                {lapatext.nav_datu}
            </div>
        );
    }
}
