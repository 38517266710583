import React from 'react';
import {Dati} from "../App";
import Highcharts, {SeriesOptionsType} from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


export const GrafiksView = (props: { dati: Dati[] }) => {

    const params = new URLSearchParams(window.location.search);
    const lang = params.get('lang') || 'lv';
    var menesis: string[];
    let lapatext:{videja_cena:string, nav_datu:string}
    switch (lang){
        case 'lv':
            menesis = require('./Menesi_short-lv.json')
            lapatext = require('../lapas_dati-lv.json')
            break;
        case 'en':
            menesis = require('./Menesi_short-en.json')
            lapatext = require('../lapas_dati-en.json')
            break;
        default:
            menesis = require('./Menesi_short-lv.json')
            lapatext = require('../lapas_dati-lv.json')

    }


    const changeDate = (date: string | undefined) => {
        if (date) {
            let datums = date.split("-")
            return `${menesis[+datums[1] - 1]}(${datums[0]})`
        }
    }

    if (props.dati && props.dati.length >= 1) {
        let assortments: string[] = [];
        var ind = 0;
        var sk = 0;
        for (let i = 0; i < props.dati.length; i++) {
            // @ts-ignore
            if (sk < props.dati[i].vertibas?.length) {
                if (props.dati[i].vertibas) {
                    // @ts-ignore
                    sk = props.dati[i].vertibas.length;
                }
                ind = i;
            }
        }
        props.dati[ind].vertibas?.map(dati => {
                // @ts-ignore
                return assortments.push(changeDate(dati.name));
            }
        )

        let dd: SeriesOptionsType[] = [];
        props.dati.map(dati => {
            let vertib: number[] = [];


            for (let i = 0; i < assortments.length; i++) {
                vertib.push(0)
            }

            dati.vertibas?.map((vertibas) => {
                var men = changeDate(vertibas.name);
                // @ts-ignore
                var ind = assortments.indexOf(men);
                if (vertibas.value != null) {
                    vertib[ind] = vertibas.value
                }
            })

            // @ts-ignore
            let strin = dati.grupa.split('|').map(segment => {
                let trimmedSegment = segment.trim();
                // @ts-ignore
                return lapatext[trimmedSegment] || trimmedSegment;
            }).join(' | ');

            var va: SeriesOptionsType = {
                type: 'line',
                name: strin,
                data: vertib
            }
            dd.push(va)


        })


        const options: Highcharts.Options = {
            title: {
                text: ''
            },
            chart: {
                type: 'line',
                style: {
                    fontFamily: "Roboto Condensed, sans-serif",
                    fontWeight: "normal"
                },
                borderColor: '#ff6a00',
                borderRadius: 20,
                borderWidth: 2
            },
            plotOptions: {
                line: {
                    dataLabels: {
                        enabled: true
                    },
                    enableMouseTracking: true
                },
                series: {
                    dataLabels: {
                        enabled: true,
                        format: '{y} €'
                    }
                }
            },
            tooltip: {valueSuffix: " eur/m3"},
            legend: {
                borderRadius: 0,
                borderWidth: 0,
                align: "center",
                x: 15
            },
            xAxis: {
                categories: assortments,
                gridLineWidth: 1,
                lineColor: "#ffc861"
            },
            yAxis: {
                title: {
                    text: lapatext.videja_cena,
                },

                plotLines: [{
                    value: 0,
                    width: 1,
                }],
                labels: {
                    formatter: function () {
                        return this.value + ' EUR';
                    }
                }
            },
            series: dd
        }


        return (
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                />
            </div>
        );
    } else {
        return (
            <div className="alert alert-danger alert-dismissible fade show">
                {lapatext.nav_datu}
            </div>
        );
    }
}
