import './App.css';

import {WoodBox} from "./components/WoodBox";
import {DataFilter} from "./models/DataFilter";
import React, {useEffect, useState} from "react";
import {DateChoose} from "./components/DateChoose";
import http from "./config/http-common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays } from '@fortawesome/free-solid-svg-icons'


import {TabulaView} from "./components/TabulaView";
import {GrafiksView} from "./components/GrafiksView";
import {GrafiksProcentiViews} from "./components/GrafiksProcentiView";

export interface SelectedData {
    noDatums?: string,
    lidzDatums?: string,
    sortiments?: Array<number>,
    suga?: Array<number>,
    kvalitate?: Array<number>,
    garums?: Array<number>,
    diametrs?: Array<number>,
    regions?: Array<number>
}

export interface WebData {
    lastMonth: number,
    lastYear: number,
    firstYear: number,
    status_date: Date
}

export interface DatiList {
    dati: Dati[]
}

export interface Dati {
    grupa?: string,
    vertibas?: {
        name?: string,
        value?: number
    }[]
}

//var menesi = ["janvāri", "februāri", "martu", "aprīli", "maiju", "jūniju", "jūliju", "augustu", "septembri", "oktobri", "novembri", "decembri"]
//var menesis = ["janvāris", "februāris", "marts", "aprīlis", "maijs", "jūnijs", "jūlijs", "augusts", "septembris", "oktobris", "novembris", "decembris"]

const params = new URLSearchParams(window.location.search);
const lang = params.get('lang') || 'lv';

let lapatext: {papildinats:string, sakot_ar_sodienu:string, sortiments:string, suga:string, kvalitate:string,
    garums:string, diametrs:string, regions:string, tabula:string, grafiks_eur:string, grafiks_proc:string};

var dataFilter: DataFilter;
var menesa:string[];
var menesis:string[]
switch (lang){
    case 'lv':
        dataFilter = require('./Productu_filtrs-lv.json')
        menesa = require('./Menesa-lv.json')
        lapatext = require('./lapas_dati-lv.json')
        menesis = require('./components/Menesi-lv.json')
        break;
    case 'en':
        dataFilter = require('./Productu_filtrs-en.json')
        menesa = require('./Menesa-en.json')
        lapatext = require('./lapas_dati-en.json')
        menesis = require('./components/Menesi-en.json')
        break;
    default:
        dataFilter = require('./Productu_filtrs-lv.json')
        menesa = require('./Menesa-lv.json')
        lapatext = require('./lapas_dati-lv.json')
        menesis = require('./components/Menesi-lv.json')
}

export const App: React.FC = () => {

    const [selectedData, setSelectedData] = useState<SelectedData>({
        regions: [(0 as number)]
    });
    const [webData, setWebData] = useState<WebData>({
        lastMonth: 0,
        lastYear: 0,
        firstYear: 0,
        status_date: new Date()
    });
    const [atlasesDati, setAtlasesDati] = useState<Dati[]>([])

    const updateSelectedData = (data: SelectedData): void => {
        setSelectedData(data)
        removeVisible();
    }

    const selectedValue: SelectedData = {};

    const [tabulaVisible, setTabulaVisible] = useState<boolean>(false)
    const [grafiksVisible, setGrafiksVisible] = useState<boolean>(false)
    const [procentiVisible, setProcentiVisible] = useState<boolean>(false)
    const [message, setMessage] = useState<string>('')
    const [valid, setValid] = useState<boolean>(false)

    const updateFromToDate = (data: WebData) => {
        let noDatums = `${data.lastYear - 1}.${data.lastMonth - 1}.01`;
        let lidzDatums = `${data.lastYear}.${data.lastMonth - 1}.01`;
        selectedValue.noDatums = noDatums;
        selectedValue.lidzDatums = lidzDatums;
        setSelectedData(selectedValue);
    };

    const updateDefoultDate = (data: WebData) => {
        let noDatums = `${data.lastYear - 1}.${data.lastMonth}.01`;
        let lidzDatums = `${data.lastYear}.${data.lastMonth}.01`;
        selectedValue.noDatums = noDatums;
        selectedValue.lidzDatums = lidzDatums;
        setSelectedData(selectedValue);
    };

    useEffect(() => {
        (async () => {
            const {data} = await http.get(`/web/webdati`);
            updateDefoultDate(data)
            setWebData(data)
        })();
    }, []);


    const validate = () => {
        if (selectedData.noDatums && selectedData.lidzDatums) {

            const [year, month, day] = selectedData.noDatums.split('.');
            const date1 = new Date(+year, +month, +day).toISOString();

            const [year1, month1, day1] = selectedData.lidzDatums.split('.');
            const date2 = new Date(+year1, +month1, +day1).toISOString();


            if (date1 > date2) {
                setMessage("No Datums ir lielāks par Līdz Datums!")
                setValid(false)
                return false;
            }
        }

        if (selectedData.sortiments && selectedData.sortiments.length > 0) {
            setValid(true)
            setMessage('')
            return true;

        } else {
            setMessage("Nav izvēlēts sortiments")
            setValid(false)
            return false;
        }
    }

    async function getData() {
        const {data} = await http.post(`/web/filter`, selectedData)
        return data;
    }


    const removeVisible = () => {
        setTabulaVisible(false);
        setGrafiksVisible(false)
        setProcentiVisible(false)
    }


    const gettabulaData = async () => {
        if (validate() && selectedData.sortiments) {
            const data = await getData();
            setAtlasesDati(data);
            setTabulaVisible(true);
            setGrafiksVisible(false)
            setProcentiVisible(false)

        } else {
            setTabulaVisible(false)
        }
    }

    const getGrafiksData = async () => {
        if (validate() && selectedData.sortiments) {
            const data = await getData();
            setAtlasesDati(data);
            setTabulaVisible(false);
            setGrafiksVisible(true)
            setProcentiVisible(false)

        } else {
            setGrafiksVisible(false)
        }
    }

    const getGrafiksProcentiData = async () => {
        if (validate() && selectedData.sortiments) {
            const data = await getData();
            setAtlasesDati(data);
            setTabulaVisible(false);
            setGrafiksVisible(false)
            setProcentiVisible(true)

        } else {
            setProcentiVisible(false)
        }
    }

    function statusDate() {
        const newDate = new Date(webData.status_date);
        var month = menesis[newDate.getMonth()]
        var day = newDate.getUTCDate();
        var year = newDate.getFullYear();
        return day + ". " + month + ", " + year
    }

let papild = lapatext.papildinats;
    papild = papild.replace("%s", String(webData.lastYear));
    papild = papild.replace("%s", menesa[webData.lastMonth - 1]);
let sakot = lapatext.sakot_ar_sodienu;
    sakot = sakot.replace("%s", menesa[webData.lastMonth - 1]);

    return (
        <div className="App">
            <div className="container">

                <div className="pricing-header mx-auto text-center">
                   
               {/* <div className="datums">
                    <span className="meta-single">
                        <FontAwesomeIcon color="#777a7f" icon={faCalendarDays}/>
                        <span className="fs-6 text-muted px-2">
                            {statusDate()}
                        </span>
                    </span>
                </div>*/}
                    <h5 className="fw-normal text-uppercase m-0">{papild}</h5>

                </div>


                <main>
                    <div className="row  text-center">
                        <WoodBox tips={lapatext.sortiments} data={dataFilter} selected={selectedData}
                                 selectedData={updateSelectedData}/>
                        <WoodBox tips={lapatext.suga} data={dataFilter} selected={selectedData}
                                 selectedData={updateSelectedData}/>
                        <WoodBox tips={lapatext.kvalitate} data={dataFilter} selected={selectedData}
                                 selectedData={updateSelectedData}/>
                        <WoodBox tips={lapatext.garums} data={dataFilter} selected={selectedData}
                                 selectedData={updateSelectedData}/>
                        <WoodBox tips={lapatext.diametrs} data={dataFilter} selected={selectedData}
                                 selectedData={updateSelectedData}/>
                        <WoodBox tips={lapatext.regions} data={dataFilter} selected={selectedData}
                                 selectedData={updateSelectedData}/>
                    </div>


                        <DateChoose webData={webData} selected={selectedData} selectedData={updateSelectedData}/>


                    <div className=" align-items-center justify-content-center">

                        <button type="button" className="poga1 btn btn-primary mx-2 my-1  fs-6 py-2"
                                onClick={gettabulaData}>{lapatext.tabula}
                        </button>
                        <button type="button" className=" btn btn-primary mx-2 my-1 fs-6 py-2"
                                onClick={getGrafiksData}>{lapatext.grafiks_eur}</button>
                        <button type="button" className=" btn btn-primary mx-2 my-1 fs-6 py-2"
                                onClick={getGrafiksProcentiData}>{lapatext.grafiks_proc}</button>
                                            </div>

                    {message && (
                        <div className="alert alert-danger alert-dismissible fade show">
                            <strong>Kļūda!</strong> {message}
                        </div>
                    )}



                    {tabulaVisible && (
                      <TabulaView  dati={atlasesDati}/>
                  )}

                    {grafiksVisible && (
                        <GrafiksView dati={atlasesDati}/>
                    )}

                    {procentiVisible && (
                        <GrafiksProcentiViews dati={atlasesDati}/>
                    )}

                    {/*<div className="datums">
                    <span className="meta-single">
                        <FontAwesomeIcon color="#777a7f" icon={faCalendarDays}/>
                        <span className="text-muted px-2">
                            <small>{statusDate()}</small>
                        </span>
                    </span>
                    </div>*/}


                </main>
            </div>
        </div>
    );
}

export default App;
