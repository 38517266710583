import './WoodBox.css';

import {DataFilter, Regions, Sortiments} from "../models/DataFilter";
import React from "react";
import {SelectedData} from "../App";

interface ChildProps {
    tips: string,
    data: DataFilter,
    selected: SelectedData,
    selectedData: (arg: SelectedData) => void
}


export const WoodBox: React.FC<ChildProps> = ({
                                                  tips,
                                                  data,
                                                  selected,
                                                  selectedData,
                                              }) => {

    const params = new URLSearchParams(window.location.search);
    const lang = params.get('lang') || 'lv';
    let lapatext:{sortiments:string, suga:string, kvalitate:string, garums:string, diametrs:string, regions:string}
    switch (lang){
        case 'lv':
            lapatext = require('../lapas_dati-lv.json')
            break;
        case 'en':
            lapatext = require('../lapas_dati-en.json')
            break;
        default:
            lapatext = require('../lapas_dati-lv.json')

    }


    let suga;
    const selectedValue: SelectedData = {};
    let assortments: number[] = [];

    function checkUncheck (arr: number[], id:number):number[] {
        if(arr.includes(id)){
            arr.splice(arr.indexOf(id), 1);
            return arr;
        }
        arr.push(id);
        return arr;
    }

    let form;

    if (tips === lapatext.sortiments ) {
        if(selected.sortiments){
            assortments = selected.sortiments.slice()
        }

        const selectChangeCheck = (id: number) => {
            assortments = checkUncheck(assortments, id)
            selectedValue.regions = selected.regions;
            selectedValue.noDatums = selected.noDatums;
            selectedValue.lidzDatums = selected.lidzDatums;

            selectedValue.sortiments = assortments;
            selectedData(selectedValue)
        };

        const dati: Sortiments[] = data.filtrs.sortiments;
        form = <ul className="list-group">
            {dati.map((sortiments, index) => {
                return (
                    <label key={index} className="list-group-item text-right" style={{ wordBreak: 'normal' }}>
                        <input className="form-check-input me-1" type="checkbox"
                               onClick={() => selectChangeCheck(sortiments.id)} itemID={sortiments.id.toString()}/>
                        {sortiments.vards}
                    </label>
                );
            })}
        </ul>
    }

    if (tips === lapatext.suga) {
        if (selected.sortiments && selected.sortiments.length === 1) {
            var id: number = +selected.sortiments[0];

            if (100 === id) {

            } else if (101 === id) {

            } else {
                suga = data.filtrs.sortiments
                    .filter((sort) => sort.id === id)
                    .map(dat => dat.suga)
                    .reduce((prev, current) => prev.concat(current), []);
            }

            if(selected.suga){
                assortments = selected.suga.slice()
            }

            const selectChangeCheck = (id: number) => {
                assortments = checkUncheck(assortments, id)
                selectedValue.sortiments = selected.sortiments;
                selectedValue.regions = selected.regions;
                selectedValue.noDatums = selected.noDatums;
                selectedValue.lidzDatums = selected.lidzDatums;
                selectedValue.suga = assortments;

                selectedData(selectedValue)
            };

            if (suga) {
                form = <ul className="list-group">
                    {suga.map((dati, index) => {
                        return (
                            <label key={index} className="list-group-item">
                                <input className="form-check-input me-1" type="checkbox"
                                       onClick={() => selectChangeCheck(dati.id)} itemID={dati.id.toString()}/>
                                {dati.vards}
                            </label>
                        );
                    })}
                </ul>

            }
        }
    }

    if (tips === lapatext.kvalitate) {

        if(selected.kvalitate){
            assortments = selected.kvalitate.slice()
        }

        const selectChangeCheck = (id: number) => {
            assortments = checkUncheck(assortments, id)
            selectedValue.sortiments = selected.sortiments
            selectedValue.suga = selected.suga;
            selectedValue.regions = selected.regions;
            selectedValue.noDatums = selected.noDatums;
            selectedValue.lidzDatums = selected.lidzDatums;
            selectedValue.kvalitate = assortments

            selectedData(selectedValue)
        };

        if (selected.sortiments && selected.suga && selected.suga.length === 1) {
            var idSortiments: number = +selected.sortiments[0];

            suga = data.filtrs.sortiments
                .filter((sort) => sort.id === idSortiments)
                .map(dat => dat.suga)
                .reduce((prev, current) => prev.concat(current), []);

            var idSuga: number = +selected.suga[0];

            var kvalitate = suga
                .filter(suga => suga.id === idSuga)
                .map(dat => dat.kvalitate)
                .reduce((prev, current) => prev.concat(current), []);

            if(kvalitate.some(v => v !== undefined)){

                form = <ul className="list-group">
                    {kvalitate.map((dati, index) => {
                        return (
                            <label key={index} className="list-group-item">
                                <input className="form-check-input me-1" type="checkbox"
                                       onClick={() => selectChangeCheck(dati.id)} itemID={dati.id.toString()}/>
                                {dati.vards}
                            </label>
                        );
                    })}
                </ul>
            }

        } else if (selected.sortiments && !selected.suga && selected.sortiments.length === 1) {
             idSortiments = +selected.sortiments[0];

            var skaidas: number = 100;
            var skelda: number = 101;

            if (skaidas === idSortiments || skelda === idSortiments) {
                kvalitate = data.filtrs.sortiments
                    .filter((sort) => sort.id === idSortiments)
                    .map(dat => dat.kvalitate)
                    .reduce((prev, current) => prev.concat(current), []);


                form = <ul className="list-group">
                    {kvalitate.map((dati, index) => {
                        return (
                            <label key={index} className="list-group-item">
                                <input className="form-check-input me-1" type="checkbox"
                                       onClick={() => selectChangeCheck(dati.id)} itemID={dati.id.toString()}/>
                                {dati.vards}
                            </label>
                        );
                    })}
                </ul>

            }
        }


    }

    if (tips === lapatext.garums) {

        if(selected.garums){
            assortments = selected.garums.slice()
        }

        const selectChangeCheck = (id: number) => {
            assortments = checkUncheck(assortments, id)
            selectedValue.sortiments = selected.sortiments
            selectedValue.suga = selected.suga;
            selectedValue.kvalitate = selected.kvalitate;
            selectedValue.regions = selected.regions;
            selectedValue.noDatums = selected.noDatums;
            selectedValue.lidzDatums = selected.lidzDatums;
            selectedValue.garums = assortments

            selectedData(selectedValue)
        };

        if (selected.sortiments && selected.suga && selected.kvalitate && selected.kvalitate.length === 1) {
            idSortiments = +selected.sortiments[0];

            suga = data.filtrs.sortiments
                .filter((sort) => sort.id === idSortiments)
                .map(dat => dat.suga)
                .reduce((prev, current) => prev.concat(current), []);

            idSuga = +selected.suga[0];

            kvalitate = suga
                .filter(suga => suga.id === idSuga)
                .map(dat => dat.kvalitate)
                .reduce((prev, current) => prev.concat(current), []);

            var idKvalitate: number = +selected.kvalitate[0];

            var garums = kvalitate
                .filter(kval => kval.id === idKvalitate)
                .map(dat => dat.garums)
                .reduce((prev, current) => prev.concat(current), []);

            if(garums.some(v => v !== undefined)) {
                form = <ul className="list-group">
                    {garums.map((dati, index) => {
                        return (
                            <label key={index} className="list-group-item">
                                <input className="form-check-input me-1" type="checkbox"
                                       onClick={() => selectChangeCheck(dati.id)} itemID={dati.id.toString()}/>
                                {dati.vards}
                            </label>
                        );
                    })}
                </ul>
            }

        }
    }

    if (tips === lapatext.diametrs) {

        if(selected.diametrs){
            assortments = selected.diametrs.slice()
        }

        const selectChangeCheck = (id: number) => {
            assortments = checkUncheck(assortments, id)
            selectedValue.sortiments = selected.sortiments
            selectedValue.suga = selected.suga;
            selectedValue.kvalitate = selected.kvalitate;
            selectedValue.garums = selected.garums;
            selectedValue.regions = selected.regions;
            selectedValue.noDatums = selected.noDatums;
            selectedValue.lidzDatums = selected.lidzDatums;
            selectedValue.diametrs = assortments
            selectedData(selectedValue)
        };

        if (selected.sortiments && selected.suga && selected.kvalitate && selected.garums && selected.garums.length === 1) {
            idSortiments = +selected.sortiments[0];

            suga = data.filtrs.sortiments
                .filter((sort) => sort.id === idSortiments)
                .map(dat => dat.suga)
                .reduce((prev, current) => prev.concat(current), []);

            idSuga = +selected.suga[0];
            kvalitate = suga
                .filter(suga => suga.id === idSuga)
                .map(dat => dat.kvalitate)
                .reduce((prev, current) => prev.concat(current), []);

            idKvalitate = +selected.kvalitate[0];
            garums = kvalitate
                .filter(kval => kval.id === idKvalitate)
                .map(dat => dat.garums)
                .reduce((prev, current) => prev.concat(current), []);

            var idKGarums: number = +selected.garums[0];
            var diametrs = garums
                .filter(kval => kval.id === idKGarums)
                .map(dat => dat.diametrs)
                .reduce((prev, current) => prev.concat(current), []);

            form = <ul className="list-group">
                {diametrs.map((dati, index) => {
                    return (
                        <label key={index} className="list-group-item">
                            <input className="form-check-input me-1" type="checkbox"
                                   onClick={() => selectChangeCheck(dati.id)} itemID={dati.id.toString()}/>
                            {dati.vards}
                        </label>
                    );
                })}
            </ul>
        }
    }

    if (tips === lapatext.regions) {
        if(selected.regions){
            assortments = selected.regions.slice()
        }

        const selectChangeCheck = (id: number) => {
            assortments = checkUncheck(assortments, id)
            selectedValue.sortiments = selected.sortiments
            selectedValue.suga = selected.suga;
            selectedValue.kvalitate = selected.kvalitate;
            selectedValue.garums = selected.garums;
            selectedValue.diametrs = selected.diametrs;
            selectedValue.noDatums = selected.noDatums;
            selectedValue.lidzDatums = selected.lidzDatums;
            selectedValue.regions = assortments
            selectedData(selectedValue)
        };

           const regions:Regions[] = data.filtrs.regions;
        form = <ul className="list-group">
            {regions.map((dati, index) => {
                var izveletes:boolean;
                if(dati.id === 0) {
                    izveletes = true
                } else {
                    izveletes = false
                }
                return (
                    <label key={index} className="list-group-item">
                        <input className="form-check-input me-1" type="checkbox"
                               onClick={() => selectChangeCheck(dati.id)} itemID={dati.id.toString()} defaultChecked={izveletes}/>
                        {dati.vards}
                    </label>
                );
            })}
        </ul>




    }


    return <div className="col-lg-2 col-md-12">
        <div className="card mb-1 rounded-3 shadow-sm">
            <div className="card-header">
                <h6 className="my-0  teksts">{tips}</h6>
            </div>
            <div className="card-body">
                {form}
            </div>
        </div>
    </div>
}
