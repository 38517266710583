import './DataChoose.css';
import React from "react";
import {SelectedData, WebData} from "../App";
import {DataFilter} from "../models/DataFilter";

interface DataChooseProps {
    webData: WebData,
    selected: SelectedData,
    selectedData: (arg: SelectedData) => void
}
const params = new URLSearchParams(window.location.search);
const lang = params.get('lang') || 'lv';
var menesis: string[];
let lapatext:{datums_no:string, datums_līdz:string}
switch (lang){
    case 'lv':
        menesis = require('./Menesi-lv.json')
        lapatext = require('../lapas_dati-lv.json')
        break;
    case 'en':
        menesis = require('./Menesi-en.json')
        lapatext = require('../lapas_dati-en.json')
        break;
    default:
        menesis = require('./Menesi-lv.json')
        lapatext = require('../lapas_dati-lv.json')
}



//var menesis = ["Janvāris", "Februāris", "Marts", "Aprīlis", "Maijs", "Jūnijs", "Jūlijs", "Augusts", "Septembris", "Oktobris", "Novembris", "Decembris"]


export const DateChoose: React.FC<DataChooseProps> = ({
                                                          webData,
                                                          selected,
                                                          selectedData,
                                                      }) => {



    const selectedValue: SelectedData = {};

    var years: number[] = [];
    for (var i: number = webData.lastYear; i >= webData.firstYear; i--) {
        years.push(i)
    }

    function undateSelectedValue() {
        selectedValue.sortiments = selected.sortiments
        selectedValue.suga = selected.suga;
        selectedValue.kvalitate = selected.kvalitate;
        selectedValue.garums = selected.garums;
        selectedValue.diametrs = selected.diametrs;
        selectedValue.regions = selected.regions
        selectedValue.lidzDatums = selected.lidzDatums;
        selectedValue.noDatums = selected.noDatums;
    }

    const selectNoYear = (event: React.ChangeEvent<HTMLSelectElement>) => {
        undateSelectedValue();
        let value = +event.target.value
        if (selected.noDatums) {
            let noDatumsYear = selected.noDatums.split(".")
            selectedValue.noDatums = `${value}.${noDatumsYear[1]}.${noDatumsYear[2]}`;
            selectedData(selectedValue)
        }
    };
    const selectNoMonth = (event: React.ChangeEvent<HTMLSelectElement>) => {
        undateSelectedValue();
        let value = +event.target.value
        if (selected.noDatums) {
            let noDatumsYear = selected.noDatums.split(".")
            selectedValue.noDatums = `${noDatumsYear[0]}.${value}.${noDatumsYear[2]}`;
            selectedData(selectedValue)
        }
    };

    const selectLidzYear = (event: React.ChangeEvent<HTMLSelectElement>) => {
        undateSelectedValue();
        let value = +event.target.value
        if (selected.lidzDatums) {
            let noDatumsYear = selected.lidzDatums.split(".")
            selectedValue.lidzDatums = `${value}.${noDatumsYear[1]}.${noDatumsYear[2]}`;
            selectedData(selectedValue)
        }
    };
    const selectLidzMonth = (event: React.ChangeEvent<HTMLSelectElement>) => {
        undateSelectedValue();
        let value = +event.target.value
        if (selected.lidzDatums) {
            let noDatumsYear = selected.lidzDatums.split(".")
            selectedValue.lidzDatums = `${noDatumsYear[0]}.${value}.${noDatumsYear[2]}`;
            selectedData(selectedValue)
        }
    };


    return <div className="row">

        <div className="col-lg-6 col-sm-12">
            <div className="row">
                <div className="col-lg-3 col-sm-12">
                    <label htmlFor="noGads" className="col-form-label fs-6">{lapatext.datums_no}</label>
                </div>
                <div className="col-lg-4 col-sm-12">
                    <select className="form-select py-1" id="noGads" onChange={selectNoYear} defaultValue={'DEFAULT'}>
                        {years.map((data, index) => {
                            var selectedVal = false;
                            if (index === 1) {
                                selectedVal = true
                            }

                            return <option className="fs-6 py-1" key={index} value={data} selected={selectedVal}>{data}</option>
                        })}
                    </select>
                </div>

                <div className="col-lg-5 col-sm-12">
                    <select className="form-select py-1" id="noNenesis" onChange={selectNoMonth} defaultValue={'DEFAULT'}>
                        {menesis.map((menesis, index) => {
                            var selectedVal = false;
                            if (index + 1 === (webData.lastMonth as number)) {
                                selectedVal = true
                            }

                            return (
                                <option className="fs-6" key={index} value={index + 1} selected={selectedVal}>{menesis}</option>
                            );
                        })}
                    </select>
                    <div className="invalid-feedback">
                        Please provide a valid state.
                    </div>
                </div>
            </div>
        </div>


        <div className="col-lg-6 col-sm-12">
            <div className="row">
                <div className="col-lg-3 col-sm-12">
                    <label htmlFor="lidzGads" className="col-form-label fs-6">{lapatext.datums_līdz}</label>
                </div>

                <div className="col-lg-4 col-sm-12">
                    <select className="form-select fs-6 py-1" id="lidzGads" onChange={selectLidzYear} defaultValue={'DEFAULT'}>
                        {years.map((data, index) => {
                            var selectedVal = false;
                            if (index === 0) {
                                selectedVal = true
                            }

                            return <option className="fs-6" key={index} value={data} selected={selectedVal}>{data}</option>
                        })}
                    </select>
                </div>

                <div className="col-lg-5 col-sm-12">
                    <select className="form-select py-1" id="lidzNenesis" onChange={selectLidzMonth} defaultValue={'DEFAULT'}>
                        {menesis.map((menesis, index) => {
                            var selectedVal = false;
                            if (index + 1 === (webData.lastMonth as number)) {
                                selectedVal = true
                            }

                            return (
                                <option className="fs-6" key={index} value={index + 1} selected={selectedVal}>{menesis}</option>
                            );
                        })}
                    </select>
                </div>
            </div>
        </div>

    </div>

}
