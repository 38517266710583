import './TabulaView.css'
import React from "react";
import {Dati} from "../App";


export const TabulaView = ( props:{dati:Dati[]}) => {

    const params = new URLSearchParams(window.location.search);
    const lang = params.get('lang') || 'lv';
    let lapatext:{datums:string, vid_cena:string}
    switch (lang){
        case 'lv':
            lapatext = require('../lapas_dati-lv.json')
            break;
        case 'en':
            lapatext = require('../lapas_dati-en.json')
            break;
        default:
            lapatext = require('../lapas_dati-lv.json')

    }






if(props.dati && props.dati.length >=1) {
    return <div className="row   text-center">
        {props.dati.map((vertiba) => {


                // @ts-ignore
                let strin = vertiba.grupa.split('|').map(segment => {
                    let trimmedSegment = segment.trim();
                    // @ts-ignore
                    return lapatext[trimmedSegment] || trimmedSegment;
                }).join(' | ');




            return (
                <div className="col kaste">

                    <div className="card mb-4 rounded-3 shadow-sm">
                        <div className="card-header">
                            <h6 className="my-0">{strin}</h6>
                        </div>
                        <div className="card-body">
                            <table className="table table-striped">
                                <thead>
                                <tr>
                                    <th scope="col text-log" className="fs-6">{lapatext.datums}</th>
                                    <th scope="col" className="fs-6">{lapatext.vid_cena}</th>
                                </tr>
                                </thead>
                                <tbody>

                                {
                                    vertiba.vertibas &&
                                    vertiba.vertibas.map((dati) => {
                                        return dati.name && (
                                            <tr>
                                                <td className="tab-value">{dati.name}</td>
                                                <td className="tab-value">{dati.value?.toFixed(2)} (EUR)</td>
                                            </tr>


                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            )
        })}

    </div>
} else {
    return (
        <div className="alert alert-danger alert-dismissible fade show">
            Nav datu izvēlētajā periodā!
        </div>
    );
}

}
